export enum TonalAudiometryHzFrequency {
  X1  = 125,
  X2  = 250,
  X3  = 500,
  X4  = 1000,
  X5  = 2000,
  X6  = 3000,
  X7  = 4000,
  X8  = 6000,
  X9  = 8000,
  X10 = 12000,
}