import React from "react";
import cx    from "classnames";
import "./Title.scss";

type ITitle = {
  className?: string,
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>

/**
 * Title Functional Component
 * @param children
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ITitle>}
 */
const Title: React.FC<ITitle> = ({children, className, ...rest}) => {

  const classes: string = cx(
    "title",
    className,
  );

  return (
    <h3 {...rest} className={classes}>
      {children}
    </h3>
  );
};

export default Title;