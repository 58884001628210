import React                  from "react";
import {useLocation}          from "react-router-dom";
import cx                     from "classnames";
import {Button, Space}        from "antd";
import ViewWrapper            from "components/Containers/ViewWrapper/ViewWrapper";
import {useRedirect}          from "hooks/specific/useRedirect";
import {RoutePath, viewOrder} from "constants/enums/routePath";
import "./Recontact.scss";
import {useHearingTest}       from "../../../contexts/hearingTest/HearingTestContext";

interface IRecontact {
  className?: string,
}

/**
 * Recontact Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IRecontact>}
 */
const Recontact: React.FC<IRecontact> = ({className}) => {
  const redirect        = useRedirect();
  const {pathname}      = useLocation();

  const classes: string = cx(
    "recontact",
    className,
  );

  function handlePositive() {
    if (!viewOrder[pathname]?.next) return;
    redirect(viewOrder[pathname].next as string);
  }

  function handleNegative() {
    redirect(RoutePath.Root);
  }

  return (
    <ViewWrapper className={classes} title="Souhaitez-vous être recontacté(e) par un audioprothésiste ?" next={false}>
      <Space size="middle">
        <Button className="recontact__yes-btn" size="large" onClick={handlePositive}>Oui</Button>
        <Button className="recontact__no-btn" size="large" onClick={handleNegative}>Non</Button>
      </Space>
    </ViewWrapper>
  );
};

export default Recontact;
