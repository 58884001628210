import React                  from "react";
import {BrowserRouter, Route} from "react-router-dom";
import Routes                 from "components/Router/Routes/Routes";
import {RoutePath}            from "constants/enums/routePath";
import {useAuth}              from "contexts/auth/AuthContext";
import Page404                from "./Routes/Page404";
import Switcher               from "./Switcher";


const Router: React.FC = () => {
  const [authed] = useAuth();
  return (
    <BrowserRouter>
      <Switcher>
        <Routes authed={authed}/>
        <Route path={RoutePath.All} component={Page404}/>
      </Switcher>
    </BrowserRouter>
  );
};

export default Router;
