import React                               from "react";
import cx                                  from "classnames";
import {Col, Radio, Row, Typography, Card} from "antd";
import {RadioChangeEvent}                  from "antd/lib/radio/interface";
import {ReactComponent as EarphonesLogo}   from "assets/img/icons/headphones.svg";
import {ReactComponent as HeadphoneLogo}   from "assets/img/icons/headset.svg";
import ViewWrapper                         from "components/Containers/ViewWrapper/ViewWrapper";
import {SoundDeviceType}                   from "constants/enums/soundDeviceType";
import {useHearingTest}                    from "contexts/hearingTest/HearingTestContext";
import {setSoundDeviceType}                from "contexts/hearingTest/actions";
import "./Headphones.scss";


const options = {
  earphones : [
    {label: "Avec fil", value: SoundDeviceType.WiredEarphones},
    {label: "Sans fil", value: SoundDeviceType.WirelessEarphones}
  ],
  headphones: [
    {label: "Avec fil", value: SoundDeviceType.WiredHeadphones},
    {label: "Sans fil", value: SoundDeviceType.WirelessHeadphones}
  ]
};


interface IHeadphones {
  className?: string,
}


/**
 * Headphones Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IHeadphones>}
 */
const Headphones: React.FC<IHeadphones> = ({className}) => {
  const [htState, htDispatch]                   = useHearingTest();
  const {soundDeviceType: soundDeviceTypeState} = htState;

  const classes: string = cx(
    "headphones",
    className,
  );


  function _handleChange(event: RadioChangeEvent) {
    htDispatch(setSoundDeviceType, event?.target?.value);
  }


  return (
    <ViewWrapper
      title="Sélectionnez votre type d'écouteurs ou de casque et mettez-le"
      className={classes}
      disableNext={!soundDeviceTypeState}
    >
      <Row gutter={15}>
        <Col xs={24} md={12}>
          <Card className="headphones__card-type">
            <Typography.Text className="headphones__card-title">Écouteurs</Typography.Text>
            <EarphonesLogo width={100}/>
            <Radio.Group
              buttonStyle="solid"
              onChange={_handleChange}
              options={options.earphones}
              optionType="button"
              size="large"
              value={soundDeviceTypeState}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="headphones__card-type">
            <Typography.Text className="headphones__card-title">Casque</Typography.Text>
            <HeadphoneLogo width={100}/>
            <Radio.Group
              buttonStyle="solid"
              onChange={_handleChange}
              options={options.headphones}
              optionType="button"
              size="large"
              value={soundDeviceTypeState}
            />
          </Card>
        </Col>
      </Row>
    </ViewWrapper>
  );
};

export default Headphones;