import React          from "react";
import ProtectedRoute from "components/Router/Routes/ProtectedRoute";
import {
  Welcome,
  Introduction,
  Headphones,
  SetupInstructions,
  Measurement,
  AgeRange,
  Questions,
  Recontact,
  PatientForm, Thanks
}                    from "components/Views";
import {RoutePath}   from "constants/enums/routePath";
import DashboardView from "../../../features/dashboard/views/DashboardView";
import Dashboard     from "../../../template-ressources/pages/Dashboard";
import AdminDashboard     from "../../../template-ressources-2/pages/Dashboard";


interface IRoutes {
  authed: boolean,
}


/**
 * Routes Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IRoutes>}
 */
const Routes: React.FC<IRoutes> = ({authed}) => (
  <>
    <ProtectedRoute authed={authed} path={RoutePath.Root} component={Welcome}/>
    <ProtectedRoute authed={authed} path={RoutePath.Intro} component={Introduction}/>
    <ProtectedRoute authed={authed} path={RoutePath.Headphones} component={Headphones}/>
    <ProtectedRoute authed={authed} path={RoutePath.SetupInstructions} component={SetupInstructions}/>
    <ProtectedRoute authed={authed} path={RoutePath.Measurement} component={Measurement}/>
    <ProtectedRoute authed={authed} path={RoutePath.AgeRange} component={AgeRange}/>
    <ProtectedRoute authed={authed} path={RoutePath.Questions} component={Questions}/>
    <ProtectedRoute authed={authed} path={RoutePath.Recontact} component={Recontact}/>
    <ProtectedRoute authed={authed} path={RoutePath.PatientForm} component={PatientForm}/>
    <ProtectedRoute authed={authed} path={RoutePath.Thanks} component={Thanks}/>
    <ProtectedRoute authed={authed} path={RoutePath.Dashboard} component={DashboardView}/>
    <ProtectedRoute authed={authed} path={RoutePath.OpticalDashboard} component={Dashboard}/>
    <ProtectedRoute authed={authed} path={RoutePath.AdminDashboard} component={AdminDashboard}/>
  </>
);

export default Routes;
