import React                                                              from "react";
import {Button, Space}                                                    from "antd";
import {MinusOutlined, PauseCircleFilled, PlayCircleFilled, PlusOutlined} from "@ant-design/icons";
import {Direction}                                                        from "constants/enums/direction";
import {useOscillation}                                                   from "hooks/specific/useOscillation";
import {useMount}                                                         from "../../../hooks/global/useMount";

interface IActivePlayer {
  frequency?: number,
  soundPosition?: Direction,
  onClick: (flag: boolean) => void,
  onChange?: (dB: number) => void,
}

export const ActivePlayer: React.FC<IActivePlayer> = ({frequency = 1000, soundPosition, onClick, onChange}) => {
  const config = { // TODO: externalize this configuration to parent
    frequency: {
      preset: frequency,
    },
    decibels: {
      preset: -60,
      chunkBase: 10
    },
    soundPosition: {
      preset: {
        both: !soundPosition,
        left: soundPosition === Direction.Left,
        right: soundPosition === Direction.Right,
      }
    }
  };
  const {decibels} = useOscillation(config);

  /**
   * TODO: Wrap it in an unmount props
   */
  useMount(() => {
    if (onChange) onChange(config.decibels.preset)
  })

  /**
   * Handle minus bouton click
   */
  function _handleMinusClick() {
    decibels.down();
    if (onChange) onChange(decibels.getValue());
  }

  /**
   * Handle user click on Pause button
   * @param event
   */
  function _handlePauseClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    onClick(false);
  }

  /**
   * Handle plus bouton click
   */
  function _handlePlusClick() {
    decibels.up();
    if (onChange) onChange(decibels.getValue());
  }

  return (
    <Space>
      <Button type="primary" shape="circle" size={"small"} icon={<MinusOutlined/>} onClick={_handleMinusClick}/>
      <Button type="primary" shape="circle" size={"large"} icon={<PauseCircleFilled/>} onClick={_handlePauseClick}/>
      <Button type="primary" shape="circle" size={"small"} icon={<PlusOutlined/>} onClick={_handlePlusClick}/>
    </Space>
  );
};

interface IOffPlayer {
  onClick: (flag: boolean) => void
}

export const OffPlayer: React.FC<IOffPlayer> = ({onClick}) => {
  function _handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    onClick(true);
  }

  return (
    <Button type="primary" shape="circle" icon={<PlayCircleFilled/>} size={"large"} onClick={_handleClick}/>
  );
};
