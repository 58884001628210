import React                  from 'react';
import PropTypes              from 'prop-types';
import { makeStyles }         from '@material-ui/core/styles';
import Tabs                   from '@material-ui/core/Tabs';
import Tab                    from '@material-ui/core/Tab';
import Typography             from '@material-ui/core/Typography';
import Box                    from '@material-ui/core/Box';
import PatientsTable          from "../../Tables/PatientsTable/PatientsTable";
import AvailablePatientsTable from "../../Tables/PatientsTable/AvailablePatientsTable";

// @ts-ignore
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// @ts-ignore
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    '& span': {
      textAlign: "center"
    }
  },
  tabPanel: {
    width: "100%",
    flexGrow: 1,
  }
}));

export default function PatientsDataTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  // @ts-ignore
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Listes des patients disponibles" {...a11yProps(0)} className={classes.tab}/>
        <Tab label="Mes patients" {...a11yProps(1)} className={classes.tab}/>
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        <AvailablePatientsTable/>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanel}>
        <PatientsTable/>
      </TabPanel>
    </div>
  );
}
