import React                           from "react";
import {RouteComponentProps}           from "react-router";
import cx                              from "classnames";
import {Radio, Row, Space, Typography} from "antd";
import {CheckboxOptionType}            from "antd/lib/checkbox/Group";
import {RadioChangeEvent}              from "antd/lib/radio/interface";
import ViewWrapper                     from "components/Containers/ViewWrapper/ViewWrapper";
import {useHearingTest}                from "contexts/hearingTest/HearingTestContext";
import {IQuestion, questions}          from "./Questions.dependencies";
import "./Questions.scss";


const options: Array<CheckboxOptionType | string> = [
  {label: "Toujours", value: "always"},
  {label: "Souvent", value: "often"},
  {label: "Parfois", value: "sometimes"},
  {label: "Rarement", value: "occasionally"},
  {label: "Jamais", value: "never"},
];


interface IQuestions {
  className?: string,
}


/**
 * Questions Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param match
 * @constructor
 * @return {React.FC<IQuestions>}
 */
const Questions: React.FC<IQuestions & RouteComponentProps> = ({className, match}) => {
  const [htState, htDispatch] = useHearingTest();

  // @ts-ignore
  const {part} = match.params;

  //Selected corrected value according to URL param
  const [title, filteredQuestions] = [
    `Questions - ${part === "1" ? "Première" : "Seconde"} partie`,
    part === "1" ? questions.slice(0, 2) : questions.slice(2),
  ];

  // Check if we need to disabled the next step
  const disableNext = ((state, questions) => (
    !questions.every(question => !!state?.hearingSensation?.[question.linkedStateProperty])
  ))(htState, filteredQuestions);

  const classes: string = cx(
    "questions",
    className,
  );

  function handleChange(e: RadioChangeEvent, action: IQuestion["action"]) {
    htDispatch(action, e.target.value);
  }


  return (
    <ViewWrapper
      className={classes}
      disableNext={disableNext}
      title={title}
    >
      <Space direction="vertical" align="center" size="large">
        {
          filteredQuestions.map(question => (
            <Row key={question.text}>
              <Space className="questions__form-part" direction="vertical" align="center" size="large">
                <Typography.Text className="questions__text">
                  {question.text}
                </Typography.Text>
                <Radio.Group
                  buttonStyle="solid"
                  onChange={e => handleChange(e, question.action)}
                  options={options}
                  optionType="button"
                  size="large"
                  value={htState?.hearingSensation?.[question.linkedStateProperty]}
                />
              </Space>
            </Row>
          ))
        }
      </Space>
    </ViewWrapper>
  );
};

export default Questions;