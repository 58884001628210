import React                              from "react";
import cx                                 from "classnames";
import {Row, Col, Typography, Card}       from "antd";
import ViewWrapper, {IViewWrapper}        from "components/Containers/ViewWrapper/ViewWrapper";
import {ReactComponent as SoundOnLogo}    from "assets/img/icons/sound_on.svg";
import {ReactComponent as QuietPlaceLogo} from "assets/img/icons/quiet_place.svg";
import {ReactComponent as NoiseLogo}      from "assets/img/icons/noise.svg";
import "./SetupInstructions.scss";

interface ISetupInstructions {
  className?: string,
}

const viewWrapperProps: IViewWrapper = {
  title: "Avant de commencer",
};

/**
 * SetupInstructions Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ISetupInstructions>}
 */
const SetupInstructions: React.FC<ISetupInstructions> = ({className}) => {
  const classes: string = cx(
    "setup-instructions",
    className,
  );

  return (
    <ViewWrapper {...viewWrapperProps} className={classes}>
      <Card>
        <Row gutter={15}>
          <Col xs={24} md={8} className="setup-instructions__col">
            <SoundOnLogo width={70} height={100}/>
            <Typography.Text className="setup-instructions__text">
              Réglez le volume sonore de votre appareil à 50%
            </Typography.Text>
          </Col>
          <Col xs={24} md={8} className="setup-instructions__col">
            <QuietPlaceLogo width={65} height={100}/>
            <Typography.Text className="setup-instructions__text">
              Assurez-vous de vous trouver dans un environnement calme
            </Typography.Text>
          </Col>
          <Col xs={24} md={8} className="setup-instructions__col">
            <NoiseLogo width={105} height={100}/>
            <Typography.Text className="setup-instructions__text">
              Désactivez la réduction de bruit
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </ViewWrapper>
  );
};

export default SetupInstructions;