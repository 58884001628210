import {useMemo} from "react";

export function useViewVariant(back: boolean = false): any {
  return useMemo(() => {
    const variants = {
      initial: {
        y: "-100vh",
      },
      animate: {
        y         : "0",
        transition: {delay: 0.2, duration: 1.5}
      },
      exit   : {
        y         : "-100vh",
        transition: {ease: "easeInOut", duration: 1.5}
      }
    };
    return {
      variants,
      initial: "initial",
      animate: "animate",
      exit   : "exit",
    };
  }, [back]);
}