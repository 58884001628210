import React from "react";
import cx from "classnames";
import { Form, Input } from "antd";
import ViewWrapper from "components/Containers/ViewWrapper/ViewWrapper";
import { useHearingTest } from "contexts/hearingTest/HearingTestContext";
import { setPatient } from "contexts/hearingTest/actions";
import "./PatientForm.scss";

interface IPatientForm {
  className?: string;
}

/**
 * PatientForm Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IPatientForm>}
 */
const PatientForm: React.FC<IPatientForm> = ({ className }) => {
  const [state, dispatch] = useHearingTest();
  const classes: string = cx("patient-form", className);

  function handleChange(updateObj: Record<string, string>) {
    const [[key, value]] = Object.entries(updateObj); // extract key/value from the object returned form onValuesChange method
    dispatch(setPatient, key, value);
  }

  const disableNext: boolean = !Object.values(state.patient).every(
    (value) => value
  );

  return (
    <ViewWrapper
      className={classes}
      disableNext={disableNext}
      title="Pour vous mettre en contact avec l'audioprothésiste le plus proche de chez vous, nous aurions besoin de quelques informations supplémentaires..."
    >
      <Form
        className="patient-form__form"
        initialValues={{ ...state.patient }}
        onValuesChange={handleChange}
        size="large"
      >
        <Form.Item
          className="patient-form__item-form"
          name="firstname"
          label="Prénom"
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="patient-form__item-form"
          name="lastname"
          label="Nom"
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="patient-form__item-form"
          name="email"
          label="Email"
          rules={[{ type: "email", message: "E-mail non valide !" }]}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="patient-form__item-form"
          name="phone"
          label="Téléphone"
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="patient-form__item-form patient-form__item-form--max-width"
          name="address"
          label="Adresse"
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="patient-form__item-form"
          name="city"
          label="Ville"
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="patient-form__item-form"
          name="postalCode"
          label="Code Postal"
          required
        >
          <Input />
        </Form.Item>
      </Form>
    </ViewWrapper>
  );
};

export default PatientForm;
