export const SET_GLOBAL_HEARING_SENSATION                         = "SET_GLOBAL_HEARING_SENSATION";
export const SET_SOUND_DEVICE_TYPE                                = "SET_SOUND_DEVICE_TYPE";
export const SET_TONAL_AUDIOMETRY_RESULT                          = "SET_TONAL_AUDIOMETRY_RESULT";
export const SET_AGE_RANGE                                        = "SET_AGE_RANGE";
export const SET_MUMBLING_HEARING_SENSATION                       = "SET_MUMBLING_HEARING_SENSATION";
export const SET_PHONE_HEARING_SENSATION                          = "SET_PHONE_HEARING_SENSATION";
export const SET_HIGH_PITCHED_SOUND_HEARING_SENSATION             = "SET_HIGH_PITCHED_SOUND_HEARING_SENSATION";
export const SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION = "SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION";
export const SET_PATIENT                                          = "SET_PATIENT";
export const RESET_ALL                                            = "RESET_ALL";
