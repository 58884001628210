import {merge} from "lodash";
import {log10} from "../../functions/Math/log";

export interface IOscillationMakerProps {
  frequency?: {
    preset?: number,
    type?: OscillatorType,
  },
  decibels?: {
    preset?: number,
    chunkBase?: number,
  },
  soundPosition?: {
    preset: {
      left?: boolean,
      right?: boolean,
      both?: boolean,
    }
  }
}


interface IOscillationMakerConfiguration {
  frequency: {
    preset: number,
    type: OscillatorType,
  },
  decibels: {
    preset: number,
    chunkBase: number,
  },
  soundPosition: {
    preset: {
      left?: boolean,
      right?: boolean,
      both?: boolean,
    }
  }
}


export class OscillationMaker {
  private _audioContext: AudioContext;
  private _config: IOscillationMakerConfiguration;
  private _decibels: number;
  private _gainNode: GainNode;
  private _oscillatorNode: OscillatorNode;
  private _stereoPannerNode: StereoPannerNode;

  constructor(config: IOscillationMakerProps = {}) {
    // @ts-ignore
    this._audioContext     = new (window.AudioContext || window.webkitAudioContext)();
    this._config           = merge({}, defaultConfiguration, config);
    this._decibels         = this._config.decibels.preset;
    this._gainNode         = this._audioContext.createGain();
    this._oscillatorNode   = this._audioContext.createOscillator();
    this._stereoPannerNode = this._audioContext.createStereoPanner();
  }


  public play() {
    this._setGain()
      ._setFrequency()
      ._setStereoPanner()
      ._connect()
      .start();
  }


  public start() {
    this._oscillatorNode.start();
  };


  public stop() {
    this._oscillatorNode.stop();
  }


  public disconnect() {
    this._gainNode.disconnect();
  }


  /**
   * Manage the gain in dB from external
   */
  public decibels(): { up: () => void, down: () => void, getValue: () => number } {
    return {
      up  : this._increaseGain.bind(this),
      down: this._decreaseGain.bind(this),
      getValue: this._getDecibels.bind(this)
    };
  }


  /**
   * Connect the oscillator node and the gain node to the audio context
   * @private
   */
  private _connect(): OscillationMaker {
    this._oscillatorNode.connect(this._gainNode);
    this._gainNode.connect(this._stereoPannerNode);
    this._stereoPannerNode.connect(this._audioContext.destination);
    return this;
  }


  /**
   * Set gain node
   * @private
   */
  private _setGain(): OscillationMaker {
    this._gainNode.gain.value = Math.pow(10, (this._decibels / 20));
    return this;
  }


  /**
   * Increase the decibels by chunk defined in configuration
   * @private
   */
  private _increaseGain(): void {
    const decibels = this._decibels + this._config.decibels.chunkBase;
    this._setDecibels(decibels);
    this._setGain();
  }


  /**
   * Decrease the volume by percent chunk defined in configuration
   * @private
   */
  private _decreaseGain(): void {
    const decibels = this._decibels - this._config.decibels.chunkBase;
    this._setDecibels(decibels);
    this._setGain();
  }

  private _getDecibels() {
    return this._decibels;
  }

  /**
   * Decibels setter
   * @param value
   * @private
   */
  private _setDecibels(value: number) {
    this._decibels = value;
  }


  /**
   * Set the freqency of oscillation
   * @param {number} frequency
   * @private
   */
  private _setFrequency(frequency: number = this._config.frequency.preset): OscillationMaker {
    this._oscillatorNode.frequency.value = frequency;
    return this;
  }

  /**
   * Set the stereo panner
   * Cf. https://developer.mozilla.org/en-US/docs/Web/API/StereoPannerNode
   * @param {number} pan - Between -1 (left) and 1 (right)
   * @private
   */
  private _setStereoPanner(pan?: number): OscillationMaker {
    let value: number;
    if (pan && pan <= 1 && pan >= -1) value = pan;
    else {
      const {both, left, right} = this._config.soundPosition.preset;
      switch (true) {
        case both:
        case left && right:
          value = 0;
          break;
        case left:
          value = -1;
          break;
        case right:
          value = 1;
          break;
        default:
          value = 0;
          break;
      }
    }
    this._stereoPannerNode.pan.value = value;
    return this;
  }


  /**
   * Set the shape of waveform to play
   * @param {OscillatorType} type - By default, it is sine
   * @private
   */
  private _setWaveformShape(type: OscillatorType): OscillationMaker {
    this._oscillatorNode.type = type;
    return this;
  }
}

const defaultConfiguration: IOscillationMakerConfiguration = {
  frequency    : {
    preset: 1000,
    type  : "sine",
  },
  decibels     : {
    preset   : 0,
    chunkBase: 20,
  },
  soundPosition: {
    preset: {}
  }
};