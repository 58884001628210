import React         from "react";
import cx                                      from "classnames";
import {Button, ButtonGroup, Link, Typography} from "@material-ui/core";
import PatientsDataTabs                        from "../containers/Tabs/PatientsDataTabs/PatientsDataTabs";

type DashboardViewProps = {
  className?: string,
}

/**
 * DashboardView Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @return {React.FC<DashboardViewProps>}
 */
const DashboardView: React.FC<DashboardViewProps> = ({className}) => {

  const classes: string = cx(
    "dashboard-view",
    className,
  );

  return (
    <div className={classes} style={{height: "100%", display: "flex", flexDirection: "column"}}>
      <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
        <Button>
          <Link href="/">
            Audiotest
          </Link>
        </Button>
        <Button>
          <Link href="/dashboard">
            Tableau de bord - Audioprothésistes
          </Link>
        </Button>
        <Button>
          <Link href="/optical/dashboard">
            Tableau de bord - Opticiens
          </Link>
        </Button>
        <Button>
          <Link href="/admin/dashboard">
            Tableau de bord - Admin
          </Link>
        </Button>
      </ButtonGroup>
      <Typography variant="h3" gutterBottom>
        Dashboard
      </Typography>
      <PatientsDataTabs/>
    </div>
  );
};

export default DashboardView;
