import React, {useReducer}     from "react";
import {ActionReducer}         from "constants/interfaces/actionReducer";
import {InitialState, reducer} from "./reducer";
import {Dispatch, State}       from "./interfaces";


/**
 * Contexts
 */
const HearingTestStateContext    = React.createContext<State>(undefined);
const HearingTestDispatchContext = React.createContext<Dispatch>(undefined);


/**
 * Global provider interface
 */
interface IHearingTestContext {
  children?: React.ReactNode,
}


/**
 * Global Provider
 * @param children
 * TODO: for perf, memoizes it
 * @constructor
 */
function HearingTestProvider({children}: IHearingTestContext): JSX.Element {
  // @ts-ignore
  const [state, dispatch] = useReducer<State, Dispatch>(reducer, InitialState);
  return (
    <HearingTestStateContext.Provider value={state}>
      <HearingTestDispatchContext.Provider value={dispatch}>
        {children}
      </HearingTestDispatchContext.Provider>
    </HearingTestStateContext.Provider>
  );
}

/**
 * State hook
 */
function useHearingTestState(): State {
  const context: State = React.useContext(HearingTestStateContext);
  if (context === undefined) {
    throw new Error("useHearingTestState must be used within a HearingTestProvider");
  }
  return context;
}


/**
 * Dispatch hook
 */
function useHearingTestDispatch() {
  const context: Dispatch = React.useContext(HearingTestDispatchContext);
  if (context === undefined) {
    throw new Error("useHearingTestDispatch must be used within a HearingTestProvider");
  }
  return (actionCallback: (...args: any[]) => ActionReducer, ...payload: any[]) => {
    const actionReducer: ActionReducer = actionCallback(...payload);
    context(actionReducer);
  };
}


/**
 * Merge state and dispatch hooks
 */
function useHearingTest(): Array<any> {
  return [useHearingTestState(), useHearingTestDispatch()];
}

export {HearingTestProvider, useHearingTestState, useHearingTestDispatch, useHearingTest};