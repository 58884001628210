import React                        from "react";
import {Switch, useLocation} from "react-router-dom";
import * as H                       from "history";
import {AnimatePresence}            from "framer-motion";
import {useAuth}                    from "contexts/auth/AuthContext";
import {useLoading}                 from "contexts/loading/LoadingContext";

interface ISwitcher {
  className?: string,
}

/**
 * Switcher Functional Component
 * @param children
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ISwitcher>}
 */
const Switcher: React.FC<ISwitcher> = ({ children, className }) => {

  const location: H.Location<H.LocationState> = useLocation(); // can be called inside the same file as BrowserRouter
  const [authed, setAuthed]                   = useAuth();
  const [loading, setLoading]                 = useLoading();

  // useLogin((event: CustomEvent) => {
  //   // If user is authed and the loading process is running (initialState = true)
  //   if (loading && authed) {
  //     setLoading(false);
  //     return;
  //   }
  //
  //   // If the user is already un-authed and if the loading isn't running (this case covers back-end issue)
  //   if (authed) return;
  //
  //   // Default behaviour
  //   setAuthToken(event?.detail?.token);
  //   if (!loading) setLoading(true);
  //   setTimeout(() => { // fake loading
  //     setLoading!(false);
  //     setAuthed(true);
  //   }, 1500);
  // });


  // useLogout(() => {
  //   // If user is un-authed and the loading process is running (initialState = true)
  //   if (loading && !authed) {
  //     setLoading(false);
  //     return;
  //   }
  //
  //   // If the user is already un-authed and if the loading isn't running (this case covers back-end issue)
  //   if (!authed) {
  //     return;
  //   }
  //
  //   // Default behaviour
  //   if (!loading) setLoading(true);
  //   setTimeout(() => { // fake loading
  //     setLoading(false);
  //     setAuthed(false);
  //   }, 900);
  // });

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.key}>
        { children }
      </Switch>
    </AnimatePresence>
  );
};

export default Switcher;