import React            from "react";
import {useLocation}    from "react-router-dom";
import cx               from "classnames";
import {motion}         from "framer-motion";
import {Button, Space}  from "antd";
import Title            from "components/Commons/Title/Title";
import StepperProgress  from "components/Containers/StepperProgress/StepperProgress";
import {viewOrder}      from "constants/enums/routePath";
import {useRedirect}    from "hooks/specific/useRedirect";
import "./ViewWrapper.scss";
import {useHistoryBack} from "hooks/specific/useHistoryBack";

export interface IViewWrapper {
  className?: string,
  title?: string,
  next?: boolean,
  disableNext?: boolean | ((...args: any[]) => boolean),
  back?: boolean,
}

/**
 * ViewWrapper Functional Component
 * @param children
 * @param {string} className - used to set a class on a higher element tag
 * @param title
 * @param next
 * @param disableNext
 * @param back
 * @constructor
 * @return {React.FC<IViewWrapper>}
 */
const ViewWrapper: React.FC<IViewWrapper> = ({
                                               children,
                                               className,
                                               title,
                                               next = true,
                                               disableNext = false,
                                               back = true,
                                             }) => {
  const redirect     = useRedirect();
  const handleBack   = useHistoryBack();
  const {pathname}   = useLocation();
  const _disableNext = typeof disableNext === "function" ? disableNext() : disableNext;

  const classes: string = cx(
    "view-wrapper",
    className,
  );

  function _handleRedirect() {
    if (next && viewOrder[pathname]?.next) redirect(viewOrder[pathname].next as string);
  }

  return (
    <motion.div className={classes}>
      <StepperProgress/>
      <Title className={"view-wrapper__title"}>{title}</Title>
      {children}
      <Space>
        {
          !!back &&
          <Button className="view-wrapper__next-btn" onClick={handleBack} size="large">
            Retour à l'étape précédente
          </Button>
        }
        {
          !!next &&
          <Button className="view-wrapper__next-btn" onClick={_handleRedirect} disabled={_disableNext} size="large">
            Étape suivante
          </Button>
        }
      </Space>
    </motion.div>
  );
};

export default ViewWrapper;