import React                    from "react";
import cx                       from "classnames";
import {Row, Col}               from "antd";
import {Content as AntdContent} from "antd/lib/layout/layout";
import "./Content.scss";
import {useLocation}            from "react-router-dom";
import {RoutePath}              from "../../../constants/enums/routePath";

interface IContent {
  className?: string,
}

/**
 * Content Functional Component
 * @param children
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IContent>}
 */
const Content: React.FC<IContent> = ({children, className}) => {
  const classes: string = cx(
    "content",
    className,
  );
  const isDashboard = [RoutePath.Dashboard, RoutePath.OpticalDashboard, RoutePath.AdminDashboard].includes(window.location.pathname);

  return (
    <AntdContent className={classes}>
      <Row justify={"center"} className="content__row">
        <Col sm={24} md={20} lg={isDashboard ? 23 : 14} xxl={isDashboard ? 23 : 10} className="content__col">
          {children}
        </Col>
      </Row>
    </AntdContent>
  );
};

export default Content;
