import React from "react";
import cx from "classnames";
import { useHistory } from "react-router";
import { Button, Layout } from "antd";
import "./Header.scss";

interface IBanner {
  className?: string;
}

/**
 * Header Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IBanner>}
 */
const Header: React.FC<IBanner> = ({ className }) => {
  const history = useHistory();

  const classes: string = cx("header", className);

  function handleBack() {
    history.goBack();
  }

  return (
    <Layout.Header className={classes} onClick={handleBack}>
      {/* <Button className="header__btn" type="primary">
        Dashboard
      </Button> */}
    </Layout.Header>
  );
};

export default Header;
