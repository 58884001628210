import React from "react";
import cx    from "classnames";
import { Layout } from 'antd';
import "./Footer.scss";

interface IFooter {
  className?: string,
}

/**
 * Footer Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IFooter>}
 */
const Footer: React.FC<IFooter> = ({className}) => {

  const classes: string = cx(
    "footer",
    className,
  );

  return (
    <Layout.Footer className={classes}>
      Mentions légales
    </Layout.Footer>
  );
};

export default Footer;