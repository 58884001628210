import React                             from "react";
import {Layout, Footer, Header, Content} from "components/Containers";
import Router                            from "components/Router/Router";
import {AuthProvider}                    from "contexts/auth/AuthContext";
import {LoadingProvider}                 from "contexts/loading/LoadingContext";
import {HearingTestProvider}             from "contexts/hearingTest/HearingTestContext";
import "antd/dist/antd.css";
import {RoutePath}                       from "../../constants/enums/routePath";
import Dexie                             from "dexie";
import {TonalAudiometryHzFrequency}      from "../../constants/enums/tonalAudiometryHzFrequency";

// @ts-ignore
function getRandomPos(max) {
  return Math.round((Math.random() * max) * 100) / 100;
}

let db    = new Dexie("testdb");
// @ts-ignore
window.db = db;
db.version(1).stores({
  patients: "++id,firstname,lastname,phone,address,postalCode,city,email,ageRange,tonalAudiometryResults,contacted,pos"
});

// @ts-ignore
window.addFakePatient = () => db.patients.add({
  firstname             : "Jean",
  lastname              : "Dujardin",
  email                 : `${Date.now()}@oss117.fr`,
  phone                 : "06666666666",
  address               : "123 rue du Jardin",
  city                  : "Paname",
  postalCode            : "75000",
  ageRange              : "0-99",
  tonalAudiometryResults: {
    leftEar : {
      [TonalAudiometryHzFrequency.X1] : null,
      [TonalAudiometryHzFrequency.X2] : null,
      [TonalAudiometryHzFrequency.X3] : null,
      [TonalAudiometryHzFrequency.X4] : -34,
      [TonalAudiometryHzFrequency.X5] : -42,
      [TonalAudiometryHzFrequency.X6] : null,
      [TonalAudiometryHzFrequency.X7] : null,
      [TonalAudiometryHzFrequency.X8] : -66,
      [TonalAudiometryHzFrequency.X9] : null,
      [TonalAudiometryHzFrequency.X10]: null,
    },
    rightEar: {
      [TonalAudiometryHzFrequency.X1] : null,
      [TonalAudiometryHzFrequency.X2] : null,
      [TonalAudiometryHzFrequency.X3] : null,
      [TonalAudiometryHzFrequency.X4] : 420,
      [TonalAudiometryHzFrequency.X5] : -99,
      [TonalAudiometryHzFrequency.X6] : null,
      [TonalAudiometryHzFrequency.X7] : null,
      [TonalAudiometryHzFrequency.X8] : -3000,
      [TonalAudiometryHzFrequency.X9] : null,
      [TonalAudiometryHzFrequency.X10]: null,
    }
  },
  contacted             : 0,
  pos: getRandomPos(20),
});

function App() {
  const isDashboard = [RoutePath.Dashboard, RoutePath.OpticalDashboard, RoutePath.AdminDashboard].includes(window.location.pathname);
  return (
    <AuthProvider>
      <LoadingProvider initialState={false}>
        <HearingTestProvider>
          <Layout>
            {!isDashboard && <Header/>}
            <Content>
              <Router/>
            </Content>
            {!isDashboard && <Footer/>}
          </Layout>
        </HearingTestProvider>
      </LoadingProvider>
    </AuthProvider>
  );
}

export default App;
