import React, {useState}         from "react";
import cx                        from "classnames";
import {Direction}               from "constants/enums/direction";
import {ActivePlayer, OffPlayer} from "./Player.dependancies";
import "./Player.scss";

interface IPlayer {
  className?: string,
  frequency?: number,
  soundPosition?: Direction,
  onChange?: (dB: number) => void,
}

/**
 * Player Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param frequency
 * @param soundPosition
 * @param onChange
 * @constructor
 * @return {React.FC<IPlayer>}
 */
const Player: React.FC<IPlayer> = ({ className, frequency, soundPosition, onChange }) => {
  const [play, setPlay] = useState(false);
  const classes: string = cx(
    "player",
    className,
  );

  function handleClick(flag: boolean) {
    setPlay(flag);
  }

  return (
    <div className={classes}>
      {
        play
          ? <ActivePlayer frequency={frequency} soundPosition={soundPosition} onClick={handleClick} onChange={onChange}/>
          : <OffPlayer onClick={handleClick}/>
      }
    </div>
  );
};

export default Player;