import {Direction}                  from "constants/enums/direction";
import {TonalAudiometryHzFrequency} from "constants/enums/tonalAudiometryHzFrequency";
import {ActionReducer}              from "constants/interfaces/actionReducer";
import { cloneDeep } from "lodash";
import {
  RESET_ALL,
  SET_AGE_RANGE,
  SET_GLOBAL_HEARING_SENSATION, SET_HIGH_PITCHED_SOUND_HEARING_SENSATION,
  SET_MUMBLING_HEARING_SENSATION,
  SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION, SET_PATIENT,
  SET_PHONE_HEARING_SENSATION,
  SET_SOUND_DEVICE_TYPE,
  SET_TONAL_AUDIOMETRY_RESULT
} from "../actionTypes";
import {State}                      from "../interfaces";


/**
 * Initial state
 * Important: set to null to significate an unmodified value
 */
export const InitialState: State = {
  hearingSensation      : {
    global                      : null,
    mumbling                    : null,
    phone                       : null,
    highPitchedSound            : null,
    noisyEnvironmentConversation: null,
  },
  soundDeviceType       : null,
  tonalAudiometryResults: {
    leftEar : {
      [TonalAudiometryHzFrequency.X1] : null,
      [TonalAudiometryHzFrequency.X2] : null,
      [TonalAudiometryHzFrequency.X3] : null,
      [TonalAudiometryHzFrequency.X4] : null,
      [TonalAudiometryHzFrequency.X5] : null,
      [TonalAudiometryHzFrequency.X6] : null,
      [TonalAudiometryHzFrequency.X7] : null,
      [TonalAudiometryHzFrequency.X8] : null,
      [TonalAudiometryHzFrequency.X9] : null,
      [TonalAudiometryHzFrequency.X10]: null,
    },
    rightEar: {
      [TonalAudiometryHzFrequency.X1] : null,
      [TonalAudiometryHzFrequency.X2] : null,
      [TonalAudiometryHzFrequency.X3] : null,
      [TonalAudiometryHzFrequency.X4] : null,
      [TonalAudiometryHzFrequency.X5] : null,
      [TonalAudiometryHzFrequency.X6] : null,
      [TonalAudiometryHzFrequency.X7] : null,
      [TonalAudiometryHzFrequency.X8] : null,
      [TonalAudiometryHzFrequency.X9] : null,
      [TonalAudiometryHzFrequency.X10]: null,
    }
  },
  ageRange              : null,
  patient               : {
    firstname : null,
    lastname  : null,
    email     : null,
    phone     : null,
    address   : null,
    city      : null,
    postalCode: null,
  }
};


/**
 * Reducer
 * @param state
 * @param action
 */
export function reducer(state: State, action: ActionReducer) {
  switch (action.type) {
    case SET_GLOBAL_HEARING_SENSATION:
      return {...state, hearingSensation: {...state!.hearingSensation, global: action.payload}};

    case SET_SOUND_DEVICE_TYPE:
      return {...state, soundDeviceType: action.payload};

    case SET_TONAL_AUDIOMETRY_RESULT:
      const {soundPosition, frequency, decibels} = action.payload;

      let soundPositionKey: string;
      if (soundPosition === Direction.Right) soundPositionKey = "rightEar";
      else if (soundPosition === Direction.Left) soundPositionKey = "leftEar";
      else throw new Error("soundPosition needs to be type of Direction enum");

      return {
        ...state,
        tonalAudiometryResults: {
          ...state!.tonalAudiometryResults,
          [soundPositionKey]: { // @ts-ignore (Cf. https://stackoverflow.com/questions/57086672/element-implicitly-has-an-any-type-because-expression-of-type-string-cant-b)
            ...state!.tonalAudiometryResults[soundPositionKey],
            [frequency]: decibels,
          }
        }
      };

    case SET_AGE_RANGE:
      return {...state, ageRange: action?.payload};

    case SET_MUMBLING_HEARING_SENSATION:
      return {...state, hearingSensation: {...state!.hearingSensation, mumbling: action.payload}};

    case SET_PHONE_HEARING_SENSATION:
      return {...state, hearingSensation: {...state!.hearingSensation, phone: action.payload}};

    case SET_HIGH_PITCHED_SOUND_HEARING_SENSATION:
      return {...state, hearingSensation: {...state!.hearingSensation, highPitchedSound: action.payload}};

    case SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION:
      return {...state, hearingSensation: {...state!.hearingSensation, noisyEnvironmentConversation: action.payload}};

    case SET_PATIENT:
      return {
        ...state,
        patient: {
          ...state!.patient,
          [action.payload.key]: action.payload.value,
        }
      }

    case RESET_ALL:
      return cloneDeep(InitialState);

    default:
      return state;
  }
}
