import React, { useMemo, useState } from "react";

/**
 * Types
 */
type State = boolean | undefined;
type Dispatch = ((bool: boolean) => void) | undefined;

/**
 * Contexts
 */
const AuthStateContext = React.createContext<State>(undefined);
const AuthDispatchContext = React.createContext<Dispatch>(undefined);

/**
 * Interface for global provider
 */
interface IAuthProvider {
  children?: React.ReactNode;
}

/**
 * Global Provider
 * @param children
 * @constructor
 */
function AuthProvider({ children }: IAuthProvider): JSX.Element {
  const [authed, setAuthed] = useState<boolean>(true); // TODO : connection to API
  return (
    <AuthStateContext.Provider value={authed}>
      <AuthDispatchContext.Provider value={setAuthed}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

/**
 * State hook
 */
function useAuthState(): State {
  const context: State = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error(
      "[mon-test-auditif] - useAuthState must be used within a AuthProvider"
    );
  }
  return context;
}

/**
 * Dispatch hook
 */
function useAuthDispatch(): Dispatch {
  const context: Dispatch = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error(
      "[mon-test-auditif] - useAuthDispatch must be used within a AuthProvider"
    );
  }
  return context;
}

/**
 * Merge state and dispatch hooks
 */
function useAuth(): Array<any> {
  return [useAuthState(), useAuthDispatch()];
}

export { AuthProvider, useAuth, useAuthState };
