import {
  RESET_ALL,
  SET_AGE_RANGE,
  SET_GLOBAL_HEARING_SENSATION,
  SET_HIGH_PITCHED_SOUND_HEARING_SENSATION,
  SET_MUMBLING_HEARING_SENSATION,
  SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION, SET_PATIENT,
  SET_PHONE_HEARING_SENSATION,
  SET_SOUND_DEVICE_TYPE,
  SET_TONAL_AUDIOMETRY_RESULT
} from "../actionTypes";
import {HearingSensation}           from "constants/enums/hearingSensation";
import {ActionReducer}              from "constants/interfaces/actionReducer";
import {Direction}                  from "constants/enums/direction";
import {SoundDeviceType}            from "constants/enums/soundDeviceType";
import {TonalAudiometryHzFrequency} from "constants/enums/tonalAudiometryHzFrequency";
import { FrequencyAdverb } from "constants/enums/frequencyAdverb";

export function setGlobalHearingSensation(sensation: HearingSensation): ActionReducer {
  return {
    type   : SET_GLOBAL_HEARING_SENSATION,
    payload: sensation
  };
}

export function setSoundDeviceType(soundDeviceType: SoundDeviceType): ActionReducer {
  return {
    type   : SET_SOUND_DEVICE_TYPE,
    payload: soundDeviceType
  };
}

export function setTonalAudiometryResult(soundPosition: Direction, frequency: TonalAudiometryHzFrequency, decibels: number): ActionReducer {
  return {
    type   : SET_TONAL_AUDIOMETRY_RESULT,
    payload: {soundPosition, frequency, decibels}
  };
}

export function setAgeRange(ageRange: string) {
  return {
    type: SET_AGE_RANGE,
    payload: ageRange,
  }
}

export function setMumblingHearingSensation(frequencyAdverb: FrequencyAdverb) {
  return {
    type: SET_MUMBLING_HEARING_SENSATION,
    payload: frequencyAdverb,
  }
}

export function setPhoneHearingSensation(frequencyAdverb: FrequencyAdverb) {
  return {
    type: SET_PHONE_HEARING_SENSATION,
    payload: frequencyAdverb,
  }
}

export function setHighPitchedSoundHearingSensation(frequencyAdverb: FrequencyAdverb) {
  return {
    type: SET_HIGH_PITCHED_SOUND_HEARING_SENSATION,
    payload: frequencyAdverb,
  }
}

export function setNoisyEnvironmentConversationHearingSensation(frequencyAdverb: FrequencyAdverb) {
  return {
    type: SET_NOISY_ENVIRONMENT_CONVERSATION_HEARING_SENSATION,
    payload: frequencyAdverb,
  }
}

export function setPatient(key: string, value: string) {
  return {
    type: SET_PATIENT,
    payload: {
      key,
      value,
    }
  }
}

export function resetAll() {
  return {
    type: RESET_ALL,
  }
}
