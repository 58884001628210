import React                  from "react";
import {useLocation}          from "react-router-dom";
import cx                     from "classnames";
import {Steps}                from "antd";
import {RoutePath, viewOrder} from "constants/enums/routePath";
import "./StepperProgress.scss";

interface IProgress {
  className?: string,
}

/**
 * StepperProgress Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IProgress>}
 */
const StepperProgress: React.FC<IProgress> = ({className}) => {
  const {pathname} = useLocation();

  const classes: string = cx(
    "stepper-progress",
    className,
  );

  /**
   * IIFE Calculated props for Antd stepper
   */
  const calculatedProps = (pathname => {
    if (pathname === RoutePath.Root) {
      return {
        current: 0,
        percent: 0,
      };
    }
    else {
      return {
        current: 1,
        percent: Math.round((viewOrder[pathname]?.order * 100) / Object.keys(viewOrder).length),
      };
    }
  })(pathname);

  /**
   * Define the description of an Antd Step component
   * @param currentStep
   */
  function _getDescription(currentStep: number) {
    switch (true) {
      case currentStep < calculatedProps.current:
        return "Terminée";
      case currentStep === calculatedProps.current:
        return "En cours";
      case currentStep > calculatedProps.current:
        return "";
    }
  }

  return (
    <Steps {...calculatedProps} className={classes}>
      {
        ["Bienvenue", "Test auditif", "Rapport"].map((title, index) => <Steps.Step key={title} title={title}
                                                                                   description={_getDescription(index)}/>)
      }
    </Steps>
  );
};

export default StepperProgress;