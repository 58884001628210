import { Helmet } from 'react-helmet';
import {
  Box, Button, ButtonGroup,
  Container,
  Grid, Link, Typography
} from "@material-ui/core";
import Budget          from 'template-ressources/components/dashboard/Budget';
import Sales           from 'template-ressources/components/dashboard/Sales';
import TasksProgress   from 'template-ressources/components/dashboard/TasksProgress';
import TotalCustomers  from 'template-ressources/components/dashboard/TotalCustomers';
import TotalProfit     from 'template-ressources/components/dashboard/TotalProfit';
import TrafficByDevice from 'template-ressources/components/dashboard/TrafficByDevice';
import React           from "react";
import GlobalStyles from 'template-ressources/components/GlobalStyles';
import theme from 'template-ressources/theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider } from '@material-ui/core';

const Dashboard = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
      <Button>
        <Link href="/">
          Audiotest
        </Link>
      </Button>
      <Button>
        <Link href="/dashboard">
          Tableau de bord - Audioprothésistes
        </Link>
      </Button>
      <Button>
        <Link href="/optical/dashboard">
          Tableau de bord - Opticiens
        </Link>
      </Button>
      <Button>
        <Link href="/admin/dashboard">
          Tableau de bord - Admin
        </Link>
      </Button>
    </ButtonGroup>
    <Typography variant="h3" gutterBottom>
      Tableau de bord - Opticien
    </Typography>
    <Helmet>
      <title>Tableau de bord - Opticien</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalCustomers />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TasksProgress />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalProfit sx={{ height: '100%' }} />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <Sales />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficByDevice sx={{ height: '100%' }} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </ThemeProvider>
);

export default Dashboard;
