import React                  from "react";
import cx                     from "classnames";
import {Layout as AntdLayout} from "antd";
import "./Layout.scss";

interface ILayout {
  className?: string,
}

/**
 * Layout Functional Component
 * @param children
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ILayout>}
 */
const Layout: React.FC<ILayout> = ({children, className}) => {

  const classes: string = cx(
    "layout",
    className,
  );

  return (
    <AntdLayout className={classes}>
      {children}
    </AntdLayout>
  );
};

export default Layout;