import React                                       from "react";
import cx                                          from "classnames";
import {Tab}                                       from "components/Commons/Tabs/Tabs";
import {Radio, Button, Card}                       from "antd";
import ViewWrapper, {IViewWrapper}                 from "components/Containers/ViewWrapper/ViewWrapper";
import {FrownOutlined, MehOutlined, SmileOutlined} from "@ant-design/icons";
import "./Introduction.scss";
import {useHearingTest}                            from "contexts/hearingTest/HearingTestContext";
import {HearingSensation}                          from "../../../constants/enums/hearingSensation";
import {RadioChangeEvent}                          from "antd/lib/radio/interface";
import {setGlobalHearingSensation}                 from "../../../contexts/hearingTest/actions";

interface IIntroduction {
  className?: string,
}

const buttons = [
  {
    id       : HearingSensation.Good,
    text     : "Bonne",
    icon     : <SmileOutlined width={30} height={30}/>,
    className: "introduction__good-btn",
  },
  {
    id       : HearingSensation.Bad,
    text     : "Mauvaise",
    icon     : <FrownOutlined width={30} height={30}/>,
    className: "introduction__bad-btn",
  },
  {
    id       : HearingSensation.Idk,
    text     : "Je ne sais pas",
    icon     : <MehOutlined width={30} height={30}/>,
    className: "introduction__idk-btn",
  },
];


/**
 * Introduction Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IIntroduction>}
 */
const Introduction: React.FC<IIntroduction> = ({className}) => {
  const [htState, htDispatch]                 = useHearingTest();
  const {global: globalHearingSensationState} = htState?.hearingSensation;

  const classes: string = cx(
    "introduction",
    className,
  );

  function _handleChange(event: RadioChangeEvent) {
    htDispatch(setGlobalHearingSensation, event?.target?.value);
  }

  return (
    <ViewWrapper title="Comment décririez vous votre audition ?" className={classes}
                 disableNext={!globalHearingSensationState}>
      <Card size="small">
        <Radio.Group
          buttonStyle="solid"
          onChange={_handleChange}
          size="large"
          value={globalHearingSensationState}
        >
          {
            buttons.map(button => (
              <Radio.Button
                key={button.id}
                value={button.id}
                className={cx("introduction__btn", button.className)}
              >
                {button.text} {button.icon}
              </Radio.Button>
            ))
          }
        </Radio.Group>
      </Card>
    </ViewWrapper>
  );
};

export default Introduction;