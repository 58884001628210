import React                      from "react";
import cx                         from "classnames";
import {Space, Typography, Radio} from "antd";
import ViewWrapper                from "components/Containers/ViewWrapper/ViewWrapper";
import {useHearingTest}           from "contexts/hearingTest/HearingTestContext";
import "./AgeRange.scss";
import {RadioChangeEvent}         from "antd/lib/radio/interface";
import {setAgeRange}              from "../../../contexts/hearingTest/actions";


interface IAgeRange {
  className?: string,
}


/**
 * AgeRange Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IAgeRange>}
 */
const AgeRange: React.FC<IAgeRange> = ({className}) => {
  const [htState, htDispatch] = useHearingTest();
  const {ageRange: ageRangeState} = htState;

  const classes: string = cx(
    "age-range",
    className,
  );

  function handleChange(e: RadioChangeEvent) {
    htDispatch(setAgeRange, e?.target?.value);
  }

  console.debug(htState)
  return (
    <ViewWrapper
      title="Félicitation ! Vous avez presque terminé. Pour valider les résultats de votre test, nous allons vous poser 4 autres questions, puis créerons votre rapport personnalisé"
      className={classes}
      disableNext={!ageRangeState}
    >
      <Space direction="vertical" align={"center"} size="large">
        <Typography.Text>
          Sélectionnez votre tranche d'âge
        </Typography.Text>
        <Radio.Group className="age-range__age-pad" buttonStyle="solid" onChange={handleChange} size="large" value={ageRangeState}>
          <Radio.Button className="age-range__age-btn" value="13-18">13-18</Radio.Button>
          <Radio.Button className="age-range__age-btn" value="19-29">19-29</Radio.Button>
          <Radio.Button className="age-range__age-btn" value="30-39">30-39</Radio.Button>
          <Radio.Button className="age-range__age-btn" value="40-49">40-49</Radio.Button>
          <Radio.Button className="age-range__age-btn" value="50-59">50-59</Radio.Button>
          <Radio.Button className="age-range__age-btn" value="60-69">60-69</Radio.Button>
          <Radio.Button className="age-range__age-btn" value="70-79">70-79</Radio.Button>
          <Radio.Button className="age-range__age-btn" value="80-89">80-89</Radio.Button>
          <Radio.Button className="age-range__age-btn" value="90+">90+</Radio.Button>
        </Radio.Group>
      </Space>
    </ViewWrapper>
  );
};

export default AgeRange;