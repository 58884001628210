import React     from "react";

// TODO
const Page404 = () => {
  return (
    <div>
      Not found (404)
    </div>
  );
};

export default Page404;
