import React from "react";
import { useHistory } from "react-router";
import * as H from "history";
import { Typography, Button } from "antd";
import { motion } from "framer-motion";
import cx from "classnames";
import Card from "components/Commons/Card/Card";
import { RoutePath } from "constants/enums/routePath";
import useEventListener from "hooks/global/useEventListener";
import { useViewVariant } from "hooks/specific/useViewVariants";
import "./Welcome.scss";
import { Action } from "history";

interface IWelcome {
  className?: string;
}

/**
 * Welcome Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IWelcome>}
 */
const Welcome: React.FC<IWelcome> = ({ className }) => {
  const history: H.History<H.LocationState> = useHistory();
  const viewVariant = useViewVariant();

  history.listen((location) => {
    if (history.action === "POP") {
      history.push(RoutePath.Root);
    } // prevent go back on this page
  });

  const classes: string = cx("welcome", className);

  function _handleRedirect(): void {
    history.push(RoutePath.Intro);
  }

  return (
    <motion.div {...viewVariant} className={classes} exit="exit">
      <Typography.Title level={2}>
        Bienvenue sur notre test auditif en ligne de 5 minutes
      </Typography.Title>
      <Typography.Title level={3} className="welcome__subtitle">
        Obtenez un aperçu de votre audition en quelques minutes avec le test
        auditif en ligne WIZARD.
      </Typography.Title>
      <Card className="welcome__card">
        <Typography.Text type="secondary">
          Aucune donnée personnelle ne sera collectée pendant l’utilisation du
          testeur auditif en ligne fourni par WIZARD. Cependant, une fois le
          test terminé, vous devrez saisir vos coordonnées pour recevoir les
          résultats du testeur auditif, ainsi que pour obtenir davantage
          d’informations sur les produits et services proposés par WIZARD.
          <br />
          <br />
          Vous avez besoin d'<span data-color="primary">écouteurs</span> ou d'un{" "}
          <span data-color="primary">casque audio</span> pour réaliser ce test.
        </Typography.Text>
      </Card>
      <Button
        className="welcome__start-btn"
        onClick={_handleRedirect}
        size="large"
      >
        Commencer le test
      </Button>
    </motion.div>
  );
};

export default Welcome;
