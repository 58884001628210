import React            from "react";
import cx               from "classnames";
import ViewWrapper      from "components/Containers/ViewWrapper/ViewWrapper";
import {Button}         from "antd";
import {useRedirect}    from "../../../hooks/specific/useRedirect";
import {RoutePath}      from "../../../constants/enums/routePath";
import {useMount}       from "../../../hooks/global/useMount";
import {useHearingTest} from "../../../contexts/hearingTest/HearingTestContext";
import {resetAll}       from "../../../contexts/hearingTest/actions";

// @ts-ignore
function getRandomPos(max) {
  return Math.round((Math.random() * max) * 100) / 100;
}

interface IThanks {
  className?: string,
}

let alreadyMounted = false;

/**
 * Thanks Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IThanks>}
 */
const Thanks: React.FC<IThanks> = ({ className }) => {
  const redirect        = useRedirect();
  const [htState, htDispatch] = useHearingTest();
  const classes: string = cx(
    "thanks",
    className,
  );

  useMount(async () => {
    if (alreadyMounted || !htState?.patient?.email || !htState?.patient?.phone) return;
    console.debug("mount")
    alreadyMounted = true;
    // @ts-ignore
    await window.db.patients.add({
      ...htState?.patient,
      ageRange: htState?.ageRange,
      tonalAudiometryResults: htState?.tonalAudiometryResults,
      contacted: 0,
      pos: getRandomPos(20)
    })
    htDispatch(resetAll);
    alreadyMounted = false
  })

  function handleRedirect() {
    redirect(RoutePath.Root);
    htDispatch(resetAll);
  }

  return (
    <ViewWrapper
      className={classes}
      title="Merci, un audioprothésiste rentrera prochainement en contact avec vous."
      next={false}
      back={false}
    >
      <Button size="large" onClick={handleRedirect}>Retour à l'accueil</Button>
    </ViewWrapper>
  );
};

export default Thanks;
