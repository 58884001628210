import React  from "react";
import cx     from "classnames";
import Loader from "components/Commons/Loader/Loader";
import "./LoaderBackground.scss";


interface ILoaderBackground {
  className?: string,
}


/**
 * Loader Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ILoaderBackground>}
 */
const LoaderBackground: React.FC<ILoaderBackground> = ({ className }) => {

  const classes: string = cx(
    "loader-background",
    className,
  );

  return (
    <div className={classes}>
      <Loader className={"loader-background__loader"}/>
      <span className={"loader-background__text"}>Chargement des données...</span>
    </div>
  );
};

export default LoaderBackground;
