import React from "react";
import cx    from "classnames";
import "./Card.scss"

interface ICard {
  className?: string,
}

/**
 * Card Functional Component
 * @param children
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ICard>}
 */
const Card: React.FC<ICard> = ({ children, className }) => {

  const classes: string = cx(
    "card",
    className,
  );

  return (
    <div className={classes}>
      { children }
    </div>
  );
};

export default Card;