import React, {useEffect, useState} from "react";
import {RouteComponentProps}        from "react-router";
import cx                           from "classnames";
import {Space, Typography}          from "antd";
import Player                       from "components/Containers/Player/Player";
import ViewWrapper                  from "components/Containers/ViewWrapper/ViewWrapper";
import {Direction}                  from "constants/enums/direction";
import {MeasurementType}            from "constants/enums/routePath";
import {TonalAudiometryHzFrequency} from "constants/enums/tonalAudiometryHzFrequency";
import {setTonalAudiometryResult}   from "contexts/hearingTest/actions";
import {useHearingTest}             from "contexts/hearingTest/HearingTestContext";
import "./Measurement.scss";


interface IMeasurement {
  className?: string,
}


/**
 * Measurement Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param match
 * @constructor
 * @return {React.FC<IMeasurement>}
 */
const Measurement: React.FC<IMeasurement & RouteComponentProps> = ({className, match}) => {
  const [htState, htDispatch]             = useHearingTest();
  const [frequency, setFrequency]         = useState<TonalAudiometryHzFrequency | null>(null);
  const [soundPosition, setSoundPosition] = useState<Direction | null>(null);


  useEffect(() => {
    // @ts-ignore
    switch (match.params.type) {
      case MeasurementType.RightLow:
        setFrequency(TonalAudiometryHzFrequency.X4);
        setSoundPosition(Direction.Right);
        break;
      case MeasurementType.Right2k:
        setFrequency(TonalAudiometryHzFrequency.X5);
        setSoundPosition(Direction.Right);
        break;
      case MeasurementType.Right6k:
        setFrequency(TonalAudiometryHzFrequency.X8);
        setSoundPosition(Direction.Right);
        break;
      case MeasurementType.LeftLow:
        setFrequency(TonalAudiometryHzFrequency.X4);
        setSoundPosition(Direction.Left);
        break;
      case MeasurementType.Left2k:
        setFrequency(TonalAudiometryHzFrequency.X5);
        setSoundPosition(Direction.Left);
        break;
      case MeasurementType.Left6k:
        setFrequency(TonalAudiometryHzFrequency.X8);
        setSoundPosition(Direction.Left);
        break;
    } // @ts-ignore
  }, [match.params.type]);


  const [text, tonalAudiometryResultState] = [
    `Oreille ${soundPosition === Direction.Right ? "droite" : "gauche"} ${frequency}Hz`,
    frequency && soundPosition && htState.tonalAudiometryResults[soundPosition === Direction.Right ? "rightEar" : "leftEar"]?.[frequency]
  ]


  const classes: string = cx(
    "measurement",
    className,
  );


  /**
   * Handle player value changes
   * @param dB
   */
  function handleChange(dB: number) {
    htDispatch(setTonalAudiometryResult, soundPosition, frequency, dB);
  }

  return (
    <ViewWrapper
      title="Appuyez sur le bouton de lecture pour lancer la tonalité"
      className={classes}
      disableNext={!tonalAudiometryResultState}
    >
      <Space direction="vertical" align="center" size="large">
        <Typography.Text className="measurement__text">
          {text}
        </Typography.Text>
        <Player
          frequency={frequency as TonalAudiometryHzFrequency}
          soundPosition={soundPosition as Direction}
          onChange={handleChange}
        />
      </Space>
    </ViewWrapper>
  );
};

export default Measurement;