import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table          from '@material-ui/core/Table';
import TableBody      from '@material-ui/core/TableBody';
import TableCell      from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead      from '@material-ui/core/TableHead';
import TableRow       from '@material-ui/core/TableRow';
import Paper          from '@material-ui/core/Paper';
import CallIcon                 from '@material-ui/icons/Call';
import {IconButton, Typography} from "@material-ui/core";
import EmailIcon                from '@material-ui/icons/Email';
import {useMount}     from "../../../../../hooks/global/useMount";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

/**
 * PatientsTable Functional Component
 */
const PatientsTable= () => {
  const classes = useStyles();
  const [rows, setRows] = useState(null);

  useMount(async () => {
    const entries = await window.db.patients.where("contacted").equals(0).toArray();
    setRows(entries);
  })

  function handleActionClick(id) {
    window.db.patients.where("id").equals(id).modify({ contacted: 1 })
    const updatedRows = rows.filter(row => row.id !== id);
    setRows(updatedRows);
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="right">Résultats 1kHz</TableCell>
            <TableCell align="right">Résultats 2kHz</TableCell>
            <TableCell align="right">Résultats 6kHz</TableCell>
            <TableCell align="right">Proximité géographique</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows || []).map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.email}
              </TableCell>
              <TableCell align="right">L {row?.tonalAudiometryResults?.leftEar["1000"]} dB - R {row?.tonalAudiometryResults?.rightEar["1000"]} dB</TableCell>
              <TableCell align="right">L {row?.tonalAudiometryResults?.leftEar["2000"]} dB - R {row?.tonalAudiometryResults?.rightEar["2000"]} dB</TableCell>
              <TableCell align="right">L {row?.tonalAudiometryResults?.leftEar["6000"]} dB - R {row?.tonalAudiometryResults?.rightEar["6000"]} dB</TableCell>
              <TableCell align="right">{row?.pos} km</TableCell>
              <TableCell align="right">
                <a href={`tel:${row.phone}`} onClick={() => handleActionClick(row.id)}>
                  <IconButton aria-label="delete">
                    <CallIcon />
                  </IconButton>
                </a>
                <a href={`mailto:${row.email}`} onClick={() => handleActionClick(row.id)}>
                  <IconButton aria-label="delete">
                    <EmailIcon />
                  </IconButton>
                </a>
              </TableCell>
            </TableRow>
          ))}
          {
            !rows?.length && (
              <TableRow>
                <Typography style={{ padding: "1rem" }}>Pas de résultats</Typography>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientsTable;
