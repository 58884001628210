import {MutableRefObject, useEffect, useRef} from "react";
import {useMount}                            from "hooks/global/useMount";
import {IOscillationMakerProps, OscillationMaker} from "utils/audio/OscillationMaker/OscillationMaker";

export function useOscillation(config?: IOscillationMakerProps): any {
  const oscillationMakerRef: MutableRefObject<OscillationMaker> = useRef(new OscillationMaker(config));

  useMount(() => {
    oscillationMakerRef.current.play();
  }, () => {
    oscillationMakerRef.current.disconnect();
  });

  return {
    decibels: oscillationMakerRef.current.decibels(),
  };
}