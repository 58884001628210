export const MeasurementPath = "/measurement/:type";
export const QuestionsPath   = "/questions/:part";

export enum MeasurementType {
  RightLow = "right-1k",
  Right2k  = "right-2k",
  Right6k  = "right-6k",
  LeftLow  = "left-1k",
  Left2k   = "left-2k",
  Left6k   = "left-6k",
}


export const RoutePath = {
  Root             : "/",
  All              : "*",
  Login            : "/signin",
  Intro            : "/introduction",
  Headphones       : "/headphones",
  SetupInstructions: "/setup-instructions",
  Measurement      : `${MeasurementPath}(${Object.values(MeasurementType).join("|")})`,
  AgeRange         : "/age-range",
  Questions        : `${QuestionsPath}(1|2)`,
  Recontact        : "/recontact",
  PatientForm      : "/patient-form",
  Thanks           : "/thanks",
  Dashboard: "/dashboard",
  OpticalDashboard: "/optical/dashboard",
  AdminDashboard: "/admin/dashboard",
};


/**
 * Ignored paths (user authed or not)
 * Note: use a duplicate enums is more efficient than looping to the first one (cf. algorithm complexity)
 */
export enum IgnoredRoutePath {
  Root  = "/",
  Login = "/signin",
}


/**
 * Inactive paths
 * Used to disable some path of the application
 * TODO: with custom browser history
 */
export enum InactiveRoutePath {

}


export const ComputedMeasurementPath = {
  RightLow: MeasurementPath.replace(":type", MeasurementType.RightLow),
  Right2k : MeasurementPath.replace(":type", MeasurementType.Right2k),
  Right6k : MeasurementPath.replace(":type", MeasurementType.Right6k),
  LeftLow : MeasurementPath.replace(":type", MeasurementType.LeftLow),
  Left2k  : MeasurementPath.replace(":type", MeasurementType.Left2k),
  Left6k  : MeasurementPath.replace(":type", MeasurementType.Left6k),
};

export const ComputedQuestionsPath = {
  1: QuestionsPath.replace(":part", "1"),
  2: QuestionsPath.replace(":part", "2"),
};

export const viewOrder = {
  [RoutePath.Root]                  : {order: 0, next: RoutePath.Intro},
  [RoutePath.Intro]                 : {order: 1, next: RoutePath.Headphones},
  [RoutePath.Headphones]            : {order: 2, next: RoutePath.SetupInstructions},
  [RoutePath.SetupInstructions]     : {order: 3, next: ComputedMeasurementPath.RightLow},
  [ComputedMeasurementPath.RightLow]: {order: 4, next: ComputedMeasurementPath.Right2k},
  [ComputedMeasurementPath.Right2k] : {order: 5, next: ComputedMeasurementPath.Right6k},
  [ComputedMeasurementPath.Right6k] : {order: 6, next: ComputedMeasurementPath.LeftLow},
  [ComputedMeasurementPath.LeftLow] : {order: 7, next: ComputedMeasurementPath.Left2k},
  [ComputedMeasurementPath.Left2k]  : {order: 8, next: ComputedMeasurementPath.Left6k},
  [ComputedMeasurementPath.Left6k]  : {order: 9, next: RoutePath.AgeRange},
  [RoutePath.AgeRange]              : {order: 10, next: ComputedQuestionsPath["1"]},
  [ComputedQuestionsPath["1"]]      : {order: 11, next: ComputedQuestionsPath["2"]},
  [ComputedQuestionsPath["2"]]      : {order: 12, next: RoutePath.Recontact},
  [RoutePath.Recontact]             : {order: 13, next: RoutePath.PatientForm},
  [RoutePath.PatientForm]           : {order: 14, next: RoutePath.Thanks},
  [RoutePath.Thanks]                : {order: 15},
};
